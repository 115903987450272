import { ReplaySubject } from 'rxjs';

type ValuesOf<T> = T[keyof T];

export type ABTestTaskListType = keyof typeof abTestList;
export type HeaderMainGroupType = ValuesOf<{
  [K in keyof typeof abTestList]: typeof abTestList[K]['headerMainGroup'];
}>;
export type ABVariantsType = ValuesOf<{
  [K in keyof typeof abTestList]: ValuesOf<typeof abTestList[K]['abTestGroupIds']>;
}>;

export interface ABTestItem<K extends keyof typeof abTestList> {
  nameOfTask?: ABTestTaskListType;
  alias?: string;
  headerMainGroup: HeaderMainGroupType;
  abTestVariants: ABVariantsType[];
  abTestGroupIds: Record<keyof typeof abTestList[K]['abTestGroupIds'], ABVariantsType>;
  resolvedValue?: ABVariantsType;
  resolvedValue$?: ReplaySubject<ABVariantsType>;
  resolvedCookieValue?: ABVariantsType;
  syncOnAuth?: boolean;
  debug?: boolean;
};

export const AB_TEST_LIST: {
  [K in keyof typeof abTestList]: ABTestItem<K>;
} = {} as any;

export const abTestList = {
  'DEVWFM3992': {
    alias: 'DEVWFM3992',
    headerMainGroup: 'ID790',
    abTestGroupIds: {
      V1: 'ID789',
      V2: 'ID790',
    },
    syncOnAuth: true,
    debug: false
  },
  'DEVWFM3993': {
    alias: 'DEVWFM3993',
    headerMainGroup: 'ID813',
    abTestGroupIds: {
      V1: 'ID812',
      V2: 'ID813',
    },
    syncOnAuth: true,
    debug: false
  },
  'DEVWFM4065': {
    alias: 'DEVWFM4065',
    headerMainGroup: 'ID809',
    abTestGroupIds: {
      V1: 'ID808',
      V2: 'ID809',
    },
    syncOnAuth: true,
    debug: false
  }
} as const;
